/* .navbar {
  background-color: #002142;
} */
.signin-container {
  text-align: center;
  display: inline-block;
  padding: 50px;
  margin: 60px auto;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
}

@media (min-width: 768px) {
  .signin-container {
    width: 50% !important;
  }
}

@media (max-width: 767px) {
  .signin-container {
    text-align: center;
    display: inline-block;
    padding: 50px;
    margin: 60px auto;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.85);
  }
}

.info-container {
  text-align: center;
  display: inline-block;
  padding: 10em;
  margin: 0px auto;
  background-color: rgba(87, 86, 86, 0.625);
  color: #fff;
  max-width: 100% !important;
  height: 60%;
  width: 100% !important;
}

@media (max-width: 767px) {
  .info-container {
    padding: 4em;
    margin-top: 3em;
  }
}

.explain-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: inline-block;
  flex: 1 1 auto;
  margin: 0px auto;
  background-color: rgb(255, 255, 255);
  color: #002142;
  width: 100% !important;
  height: 100%;
  padding-bottom: 2rem;
}

.explain-item {
  width: auto !important;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.actual-info {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.info-text {
  font-size: 1.5em;
  font-weight: 200;
}

.info-button {
  color: #fff !important;
  border-color: #002142 !important;
  background-color: #002142 !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: auto;
  height: 100%;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.card {
  box-shadow: 0 5px 5px rgba(50, 50, 93, 0.05), 0 0px 0px rgba(50, 50, 93, 0.1),
    0 2px 3px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.card:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.card-body {
  padding: 0 !important;
  padding-top: 0.5em !important;
}

.btn-warning {
  transition: 0.5s;
}

.btn-warning:focus {
  box-shadow: none !important;
  background-color: #ffc107 !important;
}

.btn-warning:hover {
  background-color: #e0a800 !important;
}

.login-button {
  /* height: 60px; */
  width: 100%;
}

html,
body,
#root {
  margin: 0px;
  height: 100%;
}

.BGImage {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .BGImage {
    justify-content: center;
  }
}

.seperator {
  height: 1px;
  border-radius: 5px;
  background-color: rgb(146, 145, 145);
}

.project-container {
  display: flex;
  border-radius: 10px;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05),
    0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
  background-color: #002142;
}

.songs-container {
  display: flex;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05),
    0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-top: 3rem;
  border-radius: 10px;
}

.about-content {
  font-size: 1.1rem;
  padding-top: 1rem;
}

.about-header {
  font-weight: 400;
  font-size: 2.6em;
}

.card-img-top {
  height: 20rem;
  object-fit: cover;
}

.card-hori {
  display: flex;
  flex: 1 1 auto;
}

.img-wrapper {
  height: 10rem;
  object-fit: cover;
}

.card {
  width: 100%;
}

.apple-music-logo {
  display: inline-block;
  overflow: hidden;
  background: url(https://tools.applemusic.com/en-us/badge-lrg.svg?kind=playlist&bubble=apple_music)
    no-repeat;
  width: 158px;
  height: 45px;
  margin-top: 1rem;
}

.footer {
  background-color: #002142;
  color: #ceb900;
  padding-top: 3rem;
  padding-bottom: 2rem;
  margin-top: auto;
  width: 100%;
}

@media (max-width: 767px) {
  .footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.pdf-link,
.pdf-link:hover {
  color: #eea200;
}

.bg-theme {
  background-color: #002142;
}

.projects {
  background-color: #002142;
  margin-top: 7rem;
}

.songs {
  padding-top: 2rem;
}

#projects {
  color: #fff;
}

.languages {
  font-size: 16px;
}

iframe {
  width: 100%;
  height: 100%;
  min-height: 250px;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.btn-project {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #eea200;
}

.btn-project:hover {
  color: #002142;
  text-decoration: none;
}

.navbar-custom {
  color: #ffffff !important;
  background-color: #002142 !important;
}

.branding {
  font-size: 1.8em !important;
  font-weight: 600;
}

.navbar-header {
  float: left;
  padding: 5px;
  text-align: center;
  width: 100%;
}

.navbar-brand {
  float: none;
}

.navbar .navbar-nav .nav-item {
  margin-right: 15px;
  font-weight: 400;
  font-size: 1.2em;
  color: #ffffff !important;
  background-color: #002142 !important;
  text-align: center;
  /* position: fixed; */
}

.nav-link:hover {
  color: #ffc107 !important;
}

.login {
  position: relative;
  margin: 60px auto;
}

.info-image {
  background-image: url(images/log.PNG);
  background-size: cover;
  height: 90%;
  opacity: 0.3;
}

.checkmark {
  width: 40px;
  display: inline;
  position: absolute;
  bottom: 6.5em;
}

@media (max-width: 767px) {
  .checkmark {
    bottom: 4.5em;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .checkmark {
    width: 25px;
    display: inline;
    position: absolute;
    bottom: 3.5em;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .checkmark {
    width: 40px;
    display: inline;
    position: absolute;
    bottom: 5em;
  }
}

.arrow-right,
.arrow-left,
.long-arrow-right,
.long-arrow-left {
  display: block;
  margin: auto;
  width: 50px;
  height: 50px;
  border-top: 8px solid #000;
  border-left: 8px solid #000;
  border-radius: 3px;
}
.arrow-right,
.long-arrow-right {
  transform: rotate(135deg);
}

.arrow-left,
.long-arrow-left {
  transform: rotate(-45deg);
}
.long-arrow-right::after,
.long-arrow-left::after {
  content: "";
  display: block;
  width: 8px;
  height: 90px;
  background-color: black;
  transform: rotate(-45deg) translate(30px, 8px);
  left: 0;
  top: 0;
}

@media (max-width: 767px) {
  .long-arrow-right {
    display: none;
  }
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}
.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

html {
  scroll-behavior: smooth;
  font-family: sans-serif, Arial, Helvetica;
}

.sheetPage {
  display: flex;
  height: auto;
  min-height: 100%;
  overflow: auto;
  flex-direction: row;
}

@media (max-width: 767px) {
  .sheetPage {
    flex-direction: column;
  }
}

.create {
  background-color: #002142 !important;
  color: #fff;
}
.create,
.sheets {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  height: auto;
  -webkit-transition: all 3s ease;
  transition: all 3s ease;
}

.content {
  padding: 20px;
  text-align: center;
  flex-direction: column;
  -webkit-animation: fadein 2s;
  animation: fadein 2s;
}

.content,
.createShrink,
.sheetsShrink {
  display: flex;
  justify-content: center;
  align-items: center;
}

.createShrink,
.sheetsShrink {
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all 1.5s ease;
  transition: all 1.5s ease;
}

@media (min-width: 768px) {
  .createShrink,
  .sheetsShrink {
    width: 0;
  }
}

@media (max-width: 767px) {
  .createShrink,
  .sheetsShrink {
    height: 0;
  }
}

.footer-link {
  color: #ceb900;
}
